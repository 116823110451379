@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Zoho Puvi';
  src: local('ZohoPuvi'), url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Black.ttf') format('ttf'),
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Bold.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Extrablack.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Extrabold.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Extralight.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Light.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Medium.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Regular.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Regular\ Italic.ttf') format('ttf'), 
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Semibold.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Semibold\ Italic.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Semibold\ Italic.ttf') format('ttf') ,
       url('../components/Fonts/OpenType-TT/Zoho\ Puvi\ Thin.ttf') format('ttf') ;
}

body {
  @apply font-zohoPuvi !important;
}
.gradient-01 {
  background-image: linear-gradient(
    270deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 26%,
    hsl(257deg 70% 86%) 39%,
    hsl(0, 0%, 93%) 50%,
    hsl(216, 56%, 98%) 61%,
    hsl(240, 6%, 93%) 74%,
    hsl(0, 0%, 97%) 100%
  );
  filter: blur(125px);
}

.hero-gradient {
  background: linear-gradient(
    97.86deg,
    #FFFFFF26 0%,
    #FFFFFF26 53.65%,
    #a134c7 100%
  );
}

.customer_tab{
  background: linear-gradient(90deg, #D5E4F8 0%, #C4D6F4 24.57%, #C7C5EF 45.42%, #D4BBE6 52.7%, #F3CCDC 68.46%, #F3D6E2 81.91%, #FEE4EA 94.03%, #FDF1F3 100%),
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%, #FFFFFF 100%);
}

.card_image:hover {
  background-image: url('../assets/images/gradiant-image.png');
}

.feature_card:hover {
  background: linear-gradient(90deg, #CEDDF6 -3.74%, #D1C5EC 47.59%, #F4D3E0 100%);
}

.testimonial_card {
  box-shadow: 2px 2px 10px 0px #67676740;
}

.slick-arrow {
  display: none !important;
}

.slick-active button {
width: 17px !important;
height: 17px !important;
background-color: #F7991E !important;
border-radius: 50% !important;
}

.slick-active button::before {
  background-color: transparent !important;
}


.slick-dots li.slick-active button::before {
  color: transparent !important;
  background-color: #F7991E !important;
  width: 17px !important;
  height: 17px !important;
}

.slick-dots li button:before {
  background: white !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  color: transparent !important;
  width: 17px !important;
height: 17px !important;
} 

.product_sidebar {
  box-shadow: 0px 2px 4px 0px #00000040;
}

.gradient_background {
  background-size: 100%;
}

.slick-dots li {
width: 17px !important;
height: 17px !important;
}

.slick-dots { 
  @apply sm:bottom-[-60px] sm:absolute relative !important; 
}

.table_head_color {
  background: linear-gradient(90deg, #D5E4F8 0%, #C4D6F4 24.57%, #C7C5EF 45.42%, #D4BBE6 52.7%, #F3CCDC 68.46%, #F3D6E2 81.91%, #FEE4EA 94.03%, #FDF1F3 100%),
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%, #FFFFFF 100%);
}

.pricing_card {
  
  border-radius: 16px;
  border: 2px solid #263B7E;
  
  }
  
  .pricing_card:hover {
    border-color: transparent;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #2E31FF, #F69722) border-box;
  box-shadow: 0px 4px 4px 0px #00000040;
  }
  
  
  .iti__selected-flag {
    background-color: transparent !important;
  }
  
  .gradient-text {
    @apply text-[30px] lg:text-[72px] lg:leading-[150px];
    background: -webkit-linear-gradient(#2E31FF, #F69722);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .button-grdient {
    background: linear-gradient(98.24deg, #2E31FF 0%, #F69722 100%);
  }